import React from "react";
import Layout from "components/Layout";
import { PageCard, DocCard } from "ducks/Search";
import { Container, Section, InternalLinkCard } from "ducks/Search/styles";
import { navigate } from "gatsby";

export default function SearchResultsPage(props) {
  const searchResults = props?.location?.state?.searchResults ?? [];
  const resultType = props?.location?.state?.resultType ?? "";

  let sectionTitle = "PAGES";
  let Card = <></>;

  switch (resultType) {
    case "pages": {
      sectionTitle = "PAGES";
      Card = PageCard;
      break;
    }
    case "documents": {
      sectionTitle = "DOCUMENTATION";
      Card = DocCard;
      break;
    }
    default: {
      Card = InternalLinkCard;
      break;
    }
  }

  return (
    <Layout title="search results">
      <Container>
        <div className="go-back" onClick={() => navigate(-1)}>
          {"< GO BACK"}
        </div>
        <Section>
          <h3>{sectionTitle}</h3>
          {searchResults.map((result, index) => (
            <Card key={`card-${index}-${result?.fields?.title}`} {...result} />
          ))}
        </Section>
      </Container>
    </Layout>
  );
}
